import React from 'react';

export default function Header(){
	return(
		<header>
			<h2 className="hd-txt">Jeopardy - Rando Questionaire</h2>
			<div>
				<p><i>Can be played <span>solo</span> or <span>w/a friend</span></i></p>
				<a href="https://www.freepik.com/vectors/">Sprites by <span>freepik</span>. Click <span>here</span></a>
			</div>
		</header>
	)
}