import React from 'react';
import Jeopardy from './Jeopardy';
//style
import '../css/App.css'
//img
// import tree from '../img/twit.png'

class App extends React.Component {
  //initialize things
  constructor(){
    //get react goodies
    super()
    this.state = {
      isLoading:false,
      jquestion:[],
      jansw:""
    }
    this.removeTags = this.removeTags.bind(this)
    this.toggleAnswer = this.toggleAnswer.bind(this)
    this.nextQues = this.nextQues.bind(this)
  }

  componentDidMount(){
    //is it loading?
    this.setState({isLoading: true})
    //https://jservice.io/api/random
    fetch("https://jservice.io/api/random")
      .then(response => response.json())
      .then(data => {
        //console.log(data)
        this.setState({
            //loading done
            isLoading:false,
            jquestion: data
          })
      })
  }
 

  removeTags(str) {
      if ((str===null) || (str==='')){
          return false;
      }else{
          str = str.toString();
      }    

    // Regular expression to identify HTML tags in 
    // the input string. Replacing the identified 
    // HTML tag with a null string.
    return str.replace( /(<([^>]+)>)/ig, '');
  }


  toggleAnswer(){
    let btn = document.getElementById("ans");
    btn.classList.remove("hide-txt");
  }

  nextQues(){
        //is it loading?
    this.setState({isLoading: true})

    fetch("https://jservice.io/api/random")
      .then(response => response.json())
      .then(data => {
        this.setState({
            //loading done
            isLoading:false,
            jquestion: data
          })
      })
  }

  render(){
    const list = this.state.jquestion.map(jobj => 
      <Jeopardy 
        key={jobj.id} 
        jlist={jobj} 
        removeTags={this.removeTags} 
        toggleAnswer={this.toggleAnswer}
        nextQues={this.nextQues}
        addScore={this.addScore}
      />
    );

    const loadin = this.state.isLoading ? 
      <div className="logogf">
        <h3>
          Loading...
        </h3>
      </div>
      : list;

    return (
      <div className="App">
          {loadin}
      </div>
    );
  } 
}

export default App;
