import React from 'react';

class Jeopardy extends React.Component{
	render(){
		return(
			<div className="jeo-card">			
				<h3 className="jeo-val"><b>For</b> <u><b>{this.props.jlist.value}</b></u></h3>
				<div className="dividr">
					<div>
						<p className="jeo-headr  jeo-txt-sh"><b>Category:</b><br/>{this.props.jlist.category.title}</p>
						<p className="jeo-qs"><b>Question:</b><br/>{this.props.jlist.question}</p>
						<p className="jeo-ans hide-txt" id="ans"><b>Answer is: </b> <br />{this.props.removeTags(this.props.jlist.answer)}</p>
					</div>
					<div className="btn-cont">	
						<button 
							type="button" 
							id="jeo-btn" 
							onClick={this.props.toggleAnswer}
							className="j-btn" >
							Display Answer
						</button>
						<button 
							type="button" 
							onClick={this.props.nextQues}
							className="j-btn">
							Next Question
						</button>
					</div>

				</div>
				   	<div className="box"></div>
				   	<div className="box_two"></div>
			</div>
		);
	}
}

export default Jeopardy;